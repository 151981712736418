import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Em, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "65px 0 65px 0",
	"sm-padding": "60px 0 60px 0",
	"quarkly-title": "Images-10"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"sm-padding": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column",
			"display": "flex",
			"width": "50%",
			"lg-width": "100%",
			"margin": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"transform": "translateY(0px)",
			"transition": "transform 0.2s ease-in-out 0s",
			"padding": "0px 0px 70% 0px",
			"width": "100%",
			"height": "auto",
			"position": "relative",
			"hover-transform": "translateY(-10px)"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"left": 0,
			"src": "https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-1.jpg?v=2024-06-10T11:53:27.141Z",
			"object-fit": "cover",
			"width": "100%",
			"top": "auto",
			"right": 0,
			"bottom": "0px",
			"min-height": "100%",
			"position": "absolute",
			"display": "block"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"flex-direction": "column",
			"lg-width": "100%",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"lg-order": "1",
			"width": "50%",
			"align-items": "flex-start",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 16px",
			"display": "flex"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 36px 0px",
			"color": "--darkL2",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "--headline3",
			"md-text-align": "left",
			"children": <Em>
				Завітайте до нас, щоб отримати шматочок сьогодні!
			</Em>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL2",
			"font": "--base",
			"lg-text-align": "center",
			"md-text-align": "left",
			"children": "Хочете більше? Завітайте до піцерії Pizza Przystan сьогодні та скуштуйте традиції. Наша піца чекає, щоб наповнити ваші моменти радістю та смаком. Пам’ятайте, кожен прийом їжі – це можливість залишити спогади, тож приготуйте свій смачний у Pizza Przystan!"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;